<template>
  <VueFlyout width="100%" group="primary" position="bottom left" :duration="3000" animation-name="notification">
    <template v-slot:body="{ item, close }">
      <div class="passport-notification" :class="item.type" @click="close">
        <IconNotificationError v-if="item.type === 'error'" class="icon" />
        <IconNotificationSuccess v-else-if="item.type === 'success'" class="icon" />
        <!-- vue-notifications doesn't let you add custom keys apparently, so I turned the text field into an object when more data I needed -->
        <!-- To stay backwards compatible I check for string or object -->
        <div class="text--flyout">
          {{ typeof item.text === 'string' ? item.text : item.text.message }}
        </div>
      </div>
    </template>
  </VueFlyout>
</template>

<script>
import IconNotificationError from '@/assets/icons/error_icon.svg';
import IconNotificationSuccess from '@/assets/icons/success_icon.svg';

export default {
  name: 'default',
  components: {
    IconNotificationError,
    IconNotificationSuccess,
  },
  props: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.vue-notification-group {
  z-index: 10000 !important;
  max-width: 400px;
  padding-left: 25px;
  margin-bottom: 40px;

  .passport-notification {
    display: flex;
    align-items: center;
    min-height: 75px;
    padding: 10px 25px;
    margin: 0 0 10px;
    font-weight: normal;
    color: black;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(black, 0.16);

    .icon {
      flex-shrink: 0;
      width: 54px;
      height: 54px;
      margin-right: 20px;
    }
  }
}
</style>
