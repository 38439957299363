import Loading from 'vue-loading-overlay';
import VueMq from 'vue-mq';
import Notifications from 'vue-notification';
import vClickOutside from 'v-click-outside';
import { Table, TableColumn, DatePicker } from 'element-ui';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import Vue from 'vue';
import { ApiService } from '@/services/api.service';
import App from './App.vue';
import router from './router';
import store from './store';
import './services/datadog.rum'; // Remove to disable Datadog RUM completely.

Vue.config.productionTip = false;

Vue.use(Loading, {
  color: '#000',
});

Vue.use(Notifications, {
  componentName: 'VueFlyout',
});

// Global registration of loading component
Vue.component('Loading', {
  extends: Loading,
  props: {
    isFullPage: () => false,
  },
});

Vue.use(vClickOutside);

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
    huge: Infinity,
  },
  defaultBreakpoint: 'sm',
});

locale.use(lang);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);

ApiService.on(401, () => {
  store.commit('setJwt', '');
  router.push('/');
});
// wip login checker
router.beforeEach((to, from, next) => {
  const routerAuthCheck = store.state.jwt;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (routerAuthCheck) {
      next();
    } else {
      router.replace('/login');
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
