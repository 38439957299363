// import ApiService from '@/services/api.service';
import router from '@/router';

export default {
  state: {
    userInfo: null,
  },

  getters: {
    userInfo: (state) => state.userInfo,
  },

  actions: {
    // login: ({ commit }, { email, password }) =>
    //   new Promise(() => {
    //     // eslint-disable-next-line
    //     console.log(email, password);
    //     commit('SET_USER', { loggedIn: true });
    //     // resolve(true);
    //     ApiService.post('/users/auth', { email, password })
    //       .then(({ data }) => {
    //         commit('SET_AUTH', data.data);
    //         console.log(data.data);
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }),
    logout: ({ dispatch }) =>
      new Promise((resolve) => {
        dispatch('clearTokenAndRedirect');
        resolve(true);
        // ApiService.post('/users/logout')
        //   .then(() => {
        //     dispatch('clearTokenAndRedirect');
        //   })
        //   .catch((error) => {
        //     reject(error);
        // });
      }),
    clearTokenAndRedirect({ commit }, noRedirect) {
      commit('PURGE_AUTH');
      if (!noRedirect) {
        router.push('/login');
      }
    },
  },

  mutations: {
    SET_USER(state, value) {
      state.userInfo = { ...state.userInfo, ...value };
    },
    PURGE_AUTH(state) {
      // localStorage.removeItem('lxp-token');
      state.userInfo = null;
    },
  },
};
