import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import user from '@/store/modules/user';
import { ApiService } from '@/services/api.service';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['user', 'groupInfo', 'dontShow', 'personalityInfo', 'triviaInfo', 'jwt'],
      rehydrated: (state) => {
        ApiService.setAuth(state.state.jwt);
      },
    }),
  ],
  state: {
    jwt: '',
    groups: [],
    groupInfo: [],
    personalityInfo: [],
    triviaInfo: [],
    dontShow: false,
  },
  mutations: {
    setJwt(state, jwt) {
      state.jwt = jwt;
      ApiService.setAuth(jwt);
    },
    setGroups(state, groups) {
      state.groups = groups;
    },
    setGroupInfo(state, groupInfo) {
      state.groupInfo = groupInfo;
    },
    setPersonalityInfo(state, personalityInfo) {
      state.personalityInfo = personalityInfo;
    },
    setTriviaInfo(state, triviaInfo) {
      state.triviaInfo = triviaInfo;
    },
    setDontShow(state, dontShow) {
      state.dontShow = dontShow;
    },
  },
  actions: {},
  modules: {
    user,
  },
});
