<template>
  <div id="app" class="digital-passport">
    <PassportSideNav v-if="showNav && navOpen" @navigated="isNavOpen = false" />
    <transition name="fade" mode="out-in">
      <router-view class="page" :key="$route.name" :class="{ 'hide-nav': !showNav }" />
    </transition>
    <PassportFlyout />
  </div>
</template>

<script>
// Utils
import 'element-ui/lib/theme-chalk/index.css';

// Components
import PassportSideNav from '@/components/PassportSideNav.vue';
import PassportFlyout from '@/components/PassportFlyout.vue';
// Icons

export default {
  name: 'App',
  components: {
    PassportSideNav,
    PassportFlyout,
  },
  created() {
    window.addEventListener('focus', this.checkLoginState);
    this.browserSupportCheck();
  },
  beforeDestroy() {
    window.removeEventListener('focus', this.checkLoginState);
  },
  data() {
    return {
      isNavOpen: false,
    };
  },
  computed: {
    showNav() {
      return this.$route.path !== '/login';
    },
    navOpen() {
      if (this.$mq === 'sm' || this.$mq === 'md' || this.$mq === 'lg') {
        return this.isNavOpen;
      }
      return true;
    },
  },
  methods: {
    toggleBodyScroll(isLocked) {
      if (isLocked) {
        document.documentElement.style = 'overflow-y: hidden;';
      } else {
        document.documentElement.style = 'overflow-y: auto;';
      }
    },
    browserSupportCheck() {
      if (
        !(
          window.navigator.userAgent.includes('Safari') ||
          window.navigator.userAgent.includes('Chrome') ||
          window.navigator.userAgent.includes('Edg') ||
          window.navigator.userAgent.includes('Firefox') ||
          window.navigator.userAgent.includes('AppleWebkit')
        )
      ) {
        this.$router.replace('/browser-unsupported');
      }
    },
    checkLoginState() {
      // if (this.$route.name !== 'login') {
      //   window.location.reload();
      // }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/theme.scss';
@import url('https://phont.io/brandon-grotesque/fonts.css');
@import '../node_modules/vue-loading-overlay/dist/vue-loading.css';
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type

#app {
  font-family: $head-font-stack;
  color: $primary-white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
  margin: 0;
  background-image: url(assets/images/bg_admin@2x.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
}

.page,
.footer {
  margin-left: 225px;
  @include bp-lg-laptop {
    margin-left: 225px;
  }
}

.page {
  z-index: 1;
  min-height: 100vh;
  padding: 30px 25px 25px 25px;

  @include bp-sm-phone-landscape {
    padding: 30px 50px 40px 50px;
  }
  &.hide-nav {
    min-height: 100vh;
    margin: 0;
  }
}

.logo {
  margin-bottom: 8em;
}
.points {
  position: absolute;
  right: 0;
  margin-right: 1em;
  font-size: 12px;
  text-transform: uppercase;
}
</style>
