<template>
  <component :is="'GInput'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="passport-input">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GInput } from '@twentyfourg/grimoire';

export default {
  name: 'PassportInput',
  inheritAttrs: false,
  extends: GInput,
  components: {
    GInput,
  },
};
</script>

<style lang="scss" scoped>
.passport-input {
  ::v-deep {
    .input-element {
      padding: 12px 15px;
      color: black;
      border: 1px solid #cbcbcb;
    }
  }
}
</style>
