import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/users',
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/examples',
    name: 'examples',
    component: () => import(/* webpackChunkName: "examples" */ '../views/Examples.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/targets',
    name: 'targets',
    component: () => import(/* webpackChunkName: "targets" */ '../views/Targets.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/quizzes',
    name: 'trivias-and-quizzes',
    component: () => import(/* webpackChunkName: "quizzes" */ '../views/Quizzes.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import(/* webpackChunkName: "analytics" */ '../views/Analytics.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/create-target',
    name: 'create-new-target',
    meta: {
      parentComponent: 'groups-and-targets',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "create-target" */ '../views/CreateTarget.vue'),
  },
  {
    path: '/edit-target/:id',
    name: 'edit-target',
    meta: {
      parentComponent: 'groups-and-targets',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "create-target" */ '../views/EditTarget.vue'),
  },
  {
    path: '/create-trivia',
    name: 'create-trivia',
    meta: {
      parentComponent: 'trivias-and-quizzes',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "create-target" */ '../views/CreateTrivia.vue'),
  },
  {
    path: '/edit-trivia/:id',
    name: 'edit-trivia',
    meta: {
      parentComponent: 'trivias-and-quizzes',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "create-target" */ '../views/EditTrivia.vue'),
  },
  {
    path: '/create-personality-quiz',
    name: 'create-personality-quiz',
    meta: {
      parentComponent: 'trivias-and-quizzes',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "create-quiz" */ '../views/CreateQuiz.vue'),
  },
  {
    path: '/edit-personality-quiz/:id',
    name: 'edit-personality-quiz',
    meta: {
      parentComponent: 'trivias-and-quizzes',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "create-quiz" */ '../views/EditQuiz.vue'),
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/Error404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    // Scroll to the top of the page on route navigation
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
