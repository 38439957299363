// Disabling no-console error to display formatted console messages for error responses.
/* eslint-disable no-console */
import axios from 'axios';

const errorLogStyle = 'color: #c33';

const defaultResponses = {
  // Unprocessable Entity (422)
  422: (error) => {
    if (error?.response?.data?.errors) {
      error.response.data.errors
        .map((err) => err.error)
        .forEach((message) => {
          console.log(`%c${message}`, errorLogStyle);
        });
    } else {
      console.log('%cUnprocessable Entity: Unknown error', errorLogStyle);
    }
  },
};

export default class ApiInstance {
  constructor(config) {
    this.instance = this.create(config);
    this.listeners = {};
  }

  /**
   * Create a new Axios instance with the provided configuration.
   *
   * @param {object} [config] Axios configuration object.
   * @returns Axios instance.
   */
  create(config) {
    // Create a new instance of axios with provided configuration.
    this.instance = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      ...config,
    });

    // Request interceptor
    // Adds the Authorization header if `token` is set.
    this.instance.interceptors.request.use((req) => {
      if (this.token) req.headers.Authorization = this.token;
      return req;
    });

    // Response interceptor
    // Calls passed in global response functions and displays errors consistent with
    // latest backend API changes.
    this.instance.interceptors.response.use(
      (response) => {
        const { status } = response;
        if (status) {
          if (defaultResponses[status]) defaultResponses[status](response);
          if (this.listeners[status] && this.listeners[status].length) {
            for (const listener of this.listeners[status]) {
              listener(response);
            }
          }
        }
        return response;
      },
      (error) => {
        const { status, statusText } = error?.response || {};
        if (status) {
          if (status >= 400) {
            const { error: title = error.message, stack, logs } = error?.response?.data;
            console.group(
              `%c${error.config.method.toUpperCase()}: ${error.config.baseURL}${
                error.config.url
              } responded with a ${status} (${statusText})`,
              errorLogStyle,
            );
            console.log(`%c${title}`, errorLogStyle);
            if (defaultResponses[status]) defaultResponses[status](error);
            if (logs) {
              console.log(`%cDatadog Logs: ${logs}`, errorLogStyle);
            }
            if (process.env.VUE_APP_ENV !== 'production' && stack) {
              console.groupCollapsed('%cAPI Stack Trace', errorLogStyle);
              console.log(stack);
              console.groupEnd();
            }
            console.groupEnd();
            if (this.listeners[status] && this.listeners[status].length) {
              for (const listener of this.listeners[status]) {
                listener(error);
              }
            }
          }
          return Promise.reject(error);
        }
        return Promise.reject(error);
      },
    );
    return this.instance;
  }

  /**
   * Add an event listener to API responses.
   * @param code   HTTP Status Code.
   * @param method Handler function to call.
   */
  on(code, method) {
    if (code && typeof method === 'function') {
      const codeString = code.toString();
      if (!this.listeners[codeString]) {
        this.listeners[codeString] = [];
      }
      this.listeners[codeString].push(method);
    }
  }

  /**
   * Remove an event listener to API responses.
   * @param code   HTTP Status Code used in `on` to be removed.
   * @param method Handler function to remove. If not provided, all events for provided code will be removed.
   */
  off(code, method) {
    if (code) {
      const codeString = code.toString();
      if (this.listeners[codeString]) {
        if (!method) {
          this.listeners[codeString] = [];
        } else {
          const index = this.listeners[codeString].findIndex((listener) => listener === method);
          if (index > -1) {
            this.listeners[codeString].splice(index, 1);
          }
        }
      }
    }
  }

  /**
   * Set the Authentication header JWT token
   *
   * @param token JWT token
   */
  setAuth(token) {
    this.token = token;
  }

  /**
   * Standardize Axios methods to be consistent.
   *
   * @param endpoint Endpoint to be called
   * @param params Parameters to pass.
   * @param config Axios configuration, if needed
   * @returns Axioes response Promise.
   */
  get(endpoint, params, config) {
    return this.instance.get(endpoint, { ...config, params });
  }

  post(endpoint, params, config) {
    return this.instance.post(endpoint, params, config);
  }

  put(endpoint, params, config) {
    return this.instance.put(endpoint, params, config);
  }

  patch(endpoint, params, config) {
    return this.instance.patch(endpoint, params, config);
  }

  delete(endpoint, params, config) {
    return this.instance.delete(endpoint, { ...config, data: params });
  }

  head(endpoint, params, config) {
    return this.instance.head(endpoint, { ...config, params });
  }
}
