<template>
  <component :is="'GButton'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="passport-button">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GButton } from '@twentyfourg/grimoire';

export default {
  name: 'PassportButton',
  inheritAttrs: false,
  extends: GButton,
  components: {
    GButton,
  },
};
</script>

<style lang="scss" scoped>
.passport-button {
  height: 40px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 3px;

  $white-100: #fff;
  $blue-100: #00b5e2;
  $blue-200: #0084d4;
  $blue-900: #00205b;
  $orange-500: #ff8200;
  $gray-500: #999898;
  $signal-error: #ed3540;
  $signal-error-hover: #dd2a35;
  &.default {
    color: $white-100;
    &:disabled {
      opacity: 0.5;
    }
    &.primary {
      color: black;
      background-color: $white-100;

      &:not(&:disabled) {
        &:hover,
        &:focus-visible {
          background-color: darken($white-100, 5%);
        }
      }
    }
    &.secondary {
      color: white;
      background-color: black;

      &:not(&:disabled) {
        &:hover,
        &:focus-visible {
          background-color: lighten(black, 10%);
        }
      }
    }
    &.accent {
      background-color: $orange-500;

      &:not(&:disabled) {
        &:hover,
        &:focus-visible {
          background-color: $gray-500;
        }
      }
    }
    &.danger {
      background-color: $signal-error;

      &:not(&:disabled) {
        &:hover,
        &:focus-visible {
          background-color: $signal-error-hover;
        }
      }
    }
  }
  &.outline {
    color: $white-100;
    border-width: 2px;
    &.primary {
      color: $white-100;
      border-color: $white-100;

      &:not(&:disabled) {
        &:hover,
        &:focus-visible {
          color: darken($white-100, 5%);
          border-color: darken($white-100, 15%);
        }
      }
    }
    &.secondary {
      color: black;
      border-color: black;

      &:not(&:disabled) {
        &:hover,
        &:focus-visible {
          color: lighten(black, 15%);
          border-color: lighten(black, 15%);
        }
      }
    }
    &.danger {
      color: $signal-error;
      border-color: $signal-error;

      &:not(&:disabled) {
        &:hover,
        &:focus-visible {
          color: darken($signal-error-hover, 5%);
          border-color: darken($signal-error-hover, 5%);
        }
      }
    }
  }
  &.text {
    font-weight: bold;
    &:disabled {
      cursor: default;
      opacity: 0.25;
    }

    &.primary {
      color: $white-100;
      &:not(&:disabled) {
        &:hover,
        &:focus-visible {
          background-color: rgba($white-100, 0.08);
        }
      }
    }
    &.secondary {
      color: $white-100;

      &:not(&:disabled) {
        &:hover,
        &:focus-visible {
          background-color: rgba($white-100, 0.08);
        }
      }
    }
    &.danger {
      color: $signal-error;
      &:not(&:disabled) {
        &:hover,
        &:focus-visible {
          color: $signal-error-hover;
          background-color: rgba($signal-error-hover, 0.08);
        }
      }
    }
  }
}
</style>
